// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
(function($) {
    'use strict';

    if (!Foundation.stylesheet) {
        Foundation._style_element = $('<style></style>').appendTo('head')[0];
        Foundation.stylesheet = Foundation._style_element.styleSheet;

        if (Foundation.stylesheet) {
            Foundation.stylesheet.cssRules = {
                length: 0
            };

            Foundation.stylesheet.insertRule = function(rule, index) {
                var media;
                var mediaMatch;
                var mediaRegex;
                var namespace;
                var ruleMatch;
                var ruleRegex;
                mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                mediaMatch = mediaRegex.exec(rule);
                media = '';

                if (mediaMatch) {
                    media = '@media ' + mediaMatch[1] + ' ';
                    rule = mediaMatch[2];
                }

                ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                ruleMatch = ruleRegex.exec(rule);
                namespace = '' + media + ruleMatch[1];
                rule = ruleMatch[2];

                return this.addRule(namespace, rule);
            };
        } else if (window.console) {
            console.log('Could not fix Foundation CSS rules...');
        }
    }
})(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}
//cercador
    $( ".tools li .buscar" ).click(function() {
        $( ".buscar-contenedor" ).toggle( "fast", function() {
            if ($('.tools li.last').hasClass("open")) {
                $('.tools li.last').removeClass("open");
            } else {
                $('.tools li.last').addClass("open");
            }
        });
        return false;
    });

    $(function(){
        $('#cercar-input').on('keyup', function() {
            if ($(this).val() == '') {
                $('.enableOnInput').prop('disabled', true);
            } else {
                $('.enableOnInput').prop('disabled', false);
            }
        });
    });
// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    //carrousel imatges
    $('.slider-responsive').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }

      ]
    });
    // ampliar imatge
    $('.popup-img').magnificPopup({
        tClose: 'Cerrar (Esc)',
        tLoading: 'Cargando…',
        closeMarkup: '<button title="%title%" type="button" class="mfp-close"><img src="/media/img/delete.png" alt="Cerrar"/></button>',
        type: 'image',
        gallery: {
            enabled: true,
            tPrev: 'Anterior',
            tNext: 'Siguiente',
            tCounter: '<span class="mfp-counter">%curr% de %total%</span>'
        },
        image: {
            cursor: null,
            markup: '<div class="mfp-figure">'+
                        '<div class="mfp-figure-container">'+
                            '<div class="mfp-close"></div>'+
                            '<div class="mfp-img"></div>'+
                            '<div class="mfp-bottom-bar">'+
                                '<div class="mfp-title"></div>'+
                                '<div class="mfp-counter"></div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'
        }

    });
    // tancar botó magnific popup
    $(document).on('click', '.mfp-close', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
    });

    // mobile-nav
    /*$('.menu-trigger').click(
        function() {
            if (!$('.main-nav').hasClass('open')) {
                $('.main-nav-container')
                    .addClass('is-visible')
                    .removeClass('is-hidden');
                $('.main-nav').addClass('open');
            } else {
                $('.main-nav-container')
                    .addClass('is-hidden')
                    .removeClass('is-visible');
                $('.main-nav').removeClass('open');
            }
            return false;
        }
    );*/
    // smooth scroll top
    $(window).scroll(function() {
        if ($(this).scrollTop() > 800) {
            $(".top").fadeIn()
        } else {
            $(".top").fadeOut()
        }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"], .js-mobile-menu, .js-contact, .main-nav__item a, .tablink)').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });
    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    if ($('#sortable').length > 0) {
        var sortableList = new List('sortable', {
            valueNames: ['entidad', 'titulo', 'importe', 'desc'],
            page: 10,
            plugins: [
                ListPagination({})
            ]
        });
    }


});
