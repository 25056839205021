$( ".main-titol.b" ).wrapInner( "<div></div>");
$("nav:first").accessibleMegaMenu({
    /* prefix for generated unique id attributes, which are required
       to indicate aria-owns, aria-controls and aria-labelledby */
    uuidPrefix: "accessible-megamenu",

    /* css class used to define the megamenu styling */
    menuClass: "nav-menu",

    /* css class for a top-level navigation item in the megamenu */
    topNavItemClass: "nav-item",

    /* css class for a megamenu panel */
    panelClass: "sub-nav",

    /* css class for a group of items within a megamenu panel */
    panelGroupClass: "sub-nav-group",

    /* css class for the hover state */
    hoverClass: "hover",

    /* css class for the focus state */
    focusClass: "focus",

    /* css class for the open state */
    openClass: "open"
});






$( ".nav-menu a" ).on( "click", function() {
    $('.desplegable button').removeClass('open');
    $('.desplegable ul').attr('aria-hidden', 'true');

});
//si es touch prevenim click als dropdowns
if (feature.touch) {
    $( ".has-sub-nav > a" ).on( "click", function(event) {
        event.preventDefault();
        event.stopPropagation();
        $('ul.sub-level-group.level-3').hide();
        return false;
    });
}



/* altura introduccion fija */
var alturaIntro = $(".grupo .introduccion-texto").height() - 150 ;
if($(window).width() > 1024){
    $('.grupo .contenido').css('margin-top',alturaIntro).css('opacity',1);
}
$(window).on("resize", function()
{
    var alturaIntro = $(".grupo .introduccion-texto").height() - 150 ;
    if($(window).width() > 1024){
        $('.grupo .contenido').css('margin-top',alturaIntro).css('opacity',1);

    }else{
      $('.grupo .contenido').css('margin-top',0);
    }
});

/* fixed scroll */
var $idioma = $('html').attr('lang');
switch($idioma) {
    case 'es':
        var textHome = 'Inicio';
        var textSearch = 'Buscar';
        var textClose = 'Close';
        break;
    default:
        var textHome = 'Inicio';
        var textSearch = 'Buscar';
        var textClose = 'Cerrar';
}
$(".menu .nav-menu").prepend('<li class="menu-fixed menu-fixed__home nav-item"><a href="'+$('#home').attr('href')+'"><img src="/media/img/logo-menu-fsjd.png" alt="' + textHome + '"></a></li>');
$(".menu .nav-menu").append('<li class="menu-fixed menu-fixed__search nav-item"><a href="'+$('#buscar').attr('href')+'"><img src="/media/img/icon-lupa.png" alt="' + textSearch + '"></a></li>');

$(".main-header .menu").stick_in_parent({
  parent: ".wrapper", // note: we must now manually provide the parent
  sticky_class : 'fixed',
});
//scroll ancores
$(function() {
  $('a[href*="#"]:not([href="#"], .tablink)').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top-50
        }, 1000);
        return false;
      }
    }
  });
});
$(window).scroll(function(){
    if ($(this).scrollTop() > 300) {
      $('.top').fadeIn();
    } else {
      $('.top').fadeOut();
    }
  });



/*---PORTADA slider */
if ( $( ".slider" ).length ) {
  var $slideImatges = $(".slider-wrapper-b .slider");

 $slideImatges.each(function(){
    var thisCarousel = $(this);
    $(this).carouFredSel({
     width   : "100%",
      responsive: true,
      auto        : {
          timeoutDuration : 6500,
          delay: 6000,
      },
      debug: false,
      prev: {
          button      : ".slider-wrapper-b .prev-btn",
          key: "left"
      },
      next: {
          button      : ".slider-wrapper-b .next-btn",
          key: "right"
      },
      pagination  : {
          container       : ".pager-wrapper",
          anchorBuilder   : function( nr ) {
              return "<span>bullet" + nr + "</span>";
          }
      },
      //Possible values: "none", "scroll", "directscroll", "fade", "crossfade", "cover", "cover-fade", "uncover" or "uncover-fade".
      //carouFredSel defines only 5 easing functions, “linear”, “swing”, “quadratic”, “cubic” and “elastic“.
      scroll      : {
          fx          : "crossfade",
          easing      : "linear",
          duration    : 600,
          pauseOnHover: true,
      },
      onCreate: onCreate
    });
    thisCarousel.swipe(
    {
        swipeLeft: function(event, direction, distance, duration, fingerCount) {
            thisCarousel.trigger('next');
        },
        swipeRight: function(event, direction, distance, duration, fingerCount) {
            thisCarousel.trigger('prev');
        },
        allowPageScroll: 'vertical',
        excludedElements: "button, input, select, textarea, .noSwipe"
    });
  });


  $(".slider-wrapper .slider").carouFredSel({
     width   : "100%",
      responsive: true,
      auto        : {
          timeoutDuration : 6500,
          delay: 6000,
      },
      debug: false,
      prev: {
          button      : ".slider-wrapper .prev-btn",
          key: "left"
      },
      next: {
          button      : ".slider-wrapper .next-btn",
          key: "right"
      },
      pagination  : {
          container       : ".pager-wrapper",
          anchorBuilder   : function( nr ) {
              return "<span>bullet" + nr + "</span>";
          }
      },
      //Possible values: "none", "scroll", "directscroll", "fade", "crossfade", "cover", "cover-fade", "uncover" or "uncover-fade".
      //carouFredSel defines only 5 easing functions, “linear”, “swing”, “quadratic”, “cubic” and “elastic“.
      scroll      : {
          fx          : "crossfade",
          easing      : "linear",
          duration    : 600,
          pauseOnHover: true,
      },
      onCreate: onCreate
    });
    $(".slider-wrapper .slider").swipe(
    {
        swipeLeft: function(event, direction, distance, duration, fingerCount) {
            $(".slider-wrapper .slider").trigger('next');
        },
        swipeRight: function(event, direction, distance, duration, fingerCount) {
            $(".slider-wrapper .slider").trigger('prev');
        },
        allowPageScroll: 'vertical',
        excludedElements: "button, input, select, textarea, .noSwipe"
    });

}
function onCreate() {
  $(window).on('resize', onResize).trigger('resize');
  $(".slider-wrapper").css("opacity","1");
}

function onResize() {
  // Get all the possible height values from the slides
  var heights = $slideImatges.children().map(function() { return $(this).height(); });
  // Find the max height and set it
  $slideImatges.parent().add($slideImatges).height(Math.max.apply(null, heights));
}


  /*------------------*/
  /* Lazy load images */
  /*------------------*/
  $("img.lazy:not('img.noLazy')").show().lazyload({
    effect: "fadeIn"
//      failure_limit: 2,
//      threshold: 400
//      skip_invisible: false
  });
$(function() {
    $("img.lazySlider").lazyload({
        event : "sporty"
    });
});

$(window).bind("load", function() {
    var timeout = setTimeout(function() {
        $("img.lazySlider").trigger("sporty")
    }, 100);
});

  $("img.lazyNoticies").lazyload({
    effect: "fadeIn",
    threshold: 50,
    failure_limit: 5//
  });
  /*----------------------*/
  /* END Lazy load images */
  /*----------------------*/

$(document).ready(function() {
    if($( window ).width() < 1000){
        $(".main-nav").attr("aria-hidden","true");
    }
    $( ".main-nav__button" ).on( "click", function() {
      var atribut = $('.menu-mobil').attr('aria-hidden');

      if(atribut == "true"){
        $(".menu-mobil").attr("aria-hidden","false").addClass("open");
        $(this).attr("aria-expanded","true").addClass("open").addClass("close");
      }else{
        $(".menu-mobil").attr("aria-hidden","true").removeClass("open");
        $(this).attr("aria-expanded","false").removeClass("open").removeClass("close");
      }
    });
    $( window ).resize(function() {
        if($( window ).width() > 1000){
            $(".menu-mobil").attr("aria-hidden","false").removeClass("open");
            $(".main-nav__button").attr("aria-expanded","false").removeClass("close").removeClass("open");
            $(".search-mobile").removeClass("open");
        }
    });

    $('.main-header .menu').addClass("load");

    /* leer más */
    $( ".leer-mas" ).on( "click", function() {
       //$(this).attr("aria-expanded","true").hide();
       if($(this).attr("aria-expanded") == 'false'){
          $(this).attr("aria-expanded","true").html('Leer menos').addClass("open");
          $("#mas-informacion").attr("aria-hidden","false");
       }else{
          $(this).attr("aria-expanded","false").html('Leer más').removeClass("open");
          $("#mas-informacion").attr("aria-hidden","true");
       }

       return false;
    });
    /* mensajes al clicar */
    $(".mensaje .texto").append('<a href="#" class="cerrar-mensaje"><img src="/media/img/valores-cerrar.svg" alt="' + textClose + '"></a>');
    $( ".mensaje .imagen" ).on( "click", function() {
       $('.mensaje .imagen').attr("aria-expanded","false").removeClass("open");
       $(".mensaje .texto").attr("aria-hidden","true");
       $(this).attr("aria-expanded","true").addClass("open");
       $(this).next(".texto").attr("aria-hidden","false");
       return false;
    });
    $( ".cerrar-mensaje" ).on( "click", function() {
       $('.mensaje .imagen').attr("aria-expanded","false").removeClass("open");
       $(".mensaje .texto").attr("aria-hidden","true");
       return false;
    });
    //biografia




        //http://heydonworks.com/practical_aria_examples/
/* 3. Progressive collapsibles
-----------------------------------------------------------------------------------------
*/

$('.collapsible h3').each(function() {

  var $this = $(this);

  // create unique id for a11y relationship

  var id = 'collapsible-' + $this.index();

  // wrap the content and make it focusable

  $this.nextUntil('h3').wrapAll('<div id="'+ id +'" aria-hidden="true">');
  var panel = $this.next();

  // Add the button inside the <h2> so both the heading and button semanics are read

  $this.wrapInner('<button aria-expanded="false" aria-controls="'+ id +'">');
  var button = $this.children('button');

  // Toggle the state properties

  button.on('click', function() {
    var state = $(this).attr('aria-expanded') === 'false' ? true : false;
    $(this).attr('aria-expanded', state);
    panel.attr('aria-hidden', !state);
    if($(this).hasClass('open')){
         $(this).removeClass('open');

      }else{
        $(this).addClass('open');

      }
  });

});
//http://heydonworks.com/practical_aria_examples/
/* 9. Simple dropdowns
-----------------------------------------------------------------------------------------
*/
    var showSubmenu = function(dropdown) {
      dropdown.attr('aria-hidden', 'false');
    };

    var hideSubmenu = function(dropdown) {
      dropdown.attr('aria-hidden', 'true');
    };


    $(document).click(function(e) {
        if (!$(e.target).is('.desplegable, .desplegable *')) {
            $('.desplegable button').removeClass('open');
            $('.desplegable ul').attr('aria-hidden', 'true');
        }
    });
    $('.desplegable [aria-haspopup]').on('click', function(e) {
      $('.desplegable button').not(this).removeClass('open');
      $('.desplegable ul').attr('aria-hidden', 'true');
      var submenu = $(this).next();
      if($(this).hasClass('open')){
         $(this).removeClass('open');
         hideSubmenu(submenu);
      }else{
        $(this).addClass('open');
        showSubmenu(submenu);
      }
       e.stopPropagation();

      return false;
    });


});